import React, { memo, useCallback, useLayoutEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, FlatList } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import FollowingTopics from '@notfallkarte/app-ui/src/components/HealthFeed/FollowingTopics';
import Theme from '@notfallkarte/app-ui/src/style/Theme';
import { useTheme } from '@notfallkarte/app-ui/src/configs/ChangeTheme';
import Container from '@notfallkarte/app-ui/src/elements/Layout/Container';
import scale from '@notfallkarte/app-ui/src/utils/scale';
import { getStatusBarHeight } from 'react-native-iphone-x-helper';

const barHeight = getStatusBarHeight();

export default memo(() => {
  return (
    <Container style={styles.container}>
      <FollowingTopics />
    </Container>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 32,
  },
  header: {
    paddingRight: 88,
    paddingLeft: 24,
    ...Theme.flexRowSpace,
  },
  cancel: {
    marginLeft: 15,
    padding: 10,
    borderWidth: 0,
    marginTop: barHeight + 24,
  },
  searchText: {
    minWidth: 264,
    marginLeft: 24,
    width: scale(263),
    height: scale(48, true),
  },
  searchBox: {
    ...Theme.searchBox,
    marginLeft: 24,
  },
  list: {
    padding: 24,
  },
});
