export const AVATAR = {
  doctor1: require('./doctor-1.png'),
  doctor2: require('./doctor-2.png'),
  doctor3: require('./doctor-3.png'),
  doctor4: require('./doctor-4.png'),
  doctor5: require('./doctor-5.png'),
  doctor6: require('./doctor-6.png'),
  doctor7: require('./doctor-7.png'),
  avatar1: require('./avatar-1.png'),
  avatar2: require('./avatar-2.png'),
  avatar3: require('./avatar-3.png'),
  avatar4: require('./avatar-4.png'),
  avatar5: require('./avatar-5.png'),
  avatar6: require('./avatar-6.png'),
  avatar7: require('./avatar-7.png'),
  avatar8: require('./avatar-8.png'),
  avatar9: require('./avatar_myra_douglas.png'),
  avatar10: require('./avatar_nancy_beck.png'),
  avatar11: require('./avatar_11.png'),
  avatar12: require('./avatar_12.png'),
  avatar13: require('./avatar_13.png'),
  bradley: require('./bradley.png'),
  sarah: require('./sarah.png'),
  wallace: require('./wallace.png'),
  joanna: require('./joanna.png'),
};
