import List from '../pages/List';
import Article from '../pages/Article';

const linking = {
  BlogList: 'blog',
  BlogArticle: 'blog/:id',
};

const stacks = [
  ['BlogList', List, 'auth', , { title: 'Magazin' }],
  ['BlogArticle', Article, 'auth', ({ params }) => params.id, { headerShown: false }],
];

export default {
  linking,
  stacks,
};