import React from 'react';
import {
  View as DefaultView,
  ViewProps,
  ViewStyle,
  PressableStateCallbackType,
} from 'react-native';
import { useTheme } from '../../configs/ChangeTheme';
import Theme from '../../style/Theme';

const Layout = ({ children, style }) => {
  const { theme } = useTheme();
  return (
    <DefaultView
      style={[
        { backgroundColor: theme.backgroundItem },
        style,
        { ...Theme.shadow },
      ]}
    >
      {children}
    </DefaultView>
  );
};
export default Layout;
