import AccountInfo from '../pages/AccountInfo';
import Activate from '../pages/Activate';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Login from '../pages/Login';
import Register from '../pages/Register';

const linking = {
  Account: 'account',
  Activate: 'activate',
  ForgotPassword: 'forgot-password',
  ResetPassword: 'reset-password',
  Login: 'login',
  Register: 'register',
};

const stacks = [
  ['Account', AccountInfo, 'auth'],
  ['Activate', Activate, 'public'],
  ['ForgotPassword', ForgotPassword, 'public'],
  ['ResetPassword', ResetPassword, 'public'],
  ['Login', Login, 'public'],
  ['Register', Register, 'public'],
];

export default {
  linking,
  stacks,
};