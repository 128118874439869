import React from 'react';
import { FormControl, Input } from 'native-base';
import { useFormContext, Controller } from 'react-hook-form';

function DateInput({ name, label }) {
  const { control, formState: { errors } } = useFormContext();

  return (
    <>
      <FormControl isRequired>
        <FormControl.Label>{label}</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onBlur={onBlur}
              onChangeText={(val) => onChange(val)}
              value={value}
            />
          )}
          name={name}
          defaultValue=""
        />
        <FormControl.ErrorMessage>
          {errors[name]?.message}
        </FormControl.ErrorMessage>
      </FormControl>
    </>
  );
}

export default DateInput;