import React from 'react';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import { Switch, HStack, useToast } from 'native-base';
import { dataService } from '@notfallkarte/app-data/services';
import Text from '@notfallkarte/app-ui/src/elements/Text';
import scale from '@notfallkarte/app-ui/src/utils/scale';
import { Colors } from '@notfallkarte/app-ui/src/configs';

function Public() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { data } = useQuery('public', dataService.getPublic, {
    onError: (error) => {
      toast.show({
        title: (error.message),
        placement: 'top',
      });
    },
  });

  const { mutate: update } = useMutation(dataService.setPublic, {
    onSuccess: (data) => {
      queryClient.setQueryData('public', data);

      toast.show({
        title: 'Gespeichert',
        placement: 'top',
      });
    },
    onError: (error) => {
      toast.show({
        title: (error.message),
        placement: 'top',
      });
    },
  });

  return (
    <HStack alignItems="center" space={4}>
      <Switch onToggle={state => update({ public: state })} isChecked={data.public} />
      <Text
        size={scale(15)}
      >
        {data.public ? 'Ihre Seite ist öffentlich' : 'Ihre Seite ist gesperrt'}
      </Text>
    </HStack>
  );
}

export default Public;