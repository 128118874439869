import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React, { lazy, memo, useMemo } from 'react';
import { View, TouchableOpacity, Image, StyleSheet } from 'react-native';
import { Routes, Colors } from '@notfallkarte/app-ui/src/configs';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import Theme from '@notfallkarte/app-ui/src/style/Theme';
import { ICON } from '@notfallkarte/app-ui/src/images/Icon';
import HomeOld from './HomeOld';
import Layout from '@notfallkarte/app-ui/src/elements/Layout/Layout';
import scale from '@notfallkarte/app-ui/src/utils/scale';

const Tab = createBottomTabNavigator();
const MainTab = memo(() => {
  return (
    <View style={{ flex: 1 }}>
      <Tab.Navigator
        screenOptions={{
          lazy: true,
        }}
        tabBar={(props) => <MyTabBar {...props} />}
      >
        <Tab.Screen name='Menu' component={HomeOld} options={{ headerShown: false }} />
        <Tab.Screen name='Menu2' component={HomeOld} options={{ headerShown: false }} />
        <Tab.Screen name='Menu3' component={HomeOld} options={{ headerShown: false }} />
        <Tab.Screen name='Menu4' component={HomeOld} options={{ headerShown: false }} />
        <Tab.Screen name='Menu5' component={HomeOld} options={{ headerShown: false }} />
      </Tab.Navigator>
    </View>
  );
});

const MyTabBar = ({ state, descriptors, navigation }) => {
  const focusedOptions = descriptors[state.routes[state.index].key].options;

  if (focusedOptions.tabBarVisible === false) {
    return null;
  }
  return useMemo(() => {
    return (
      <Layout style={styles.container}>
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;

          const isFocused = state.index === index;

          const onPress = () => {
            return navigation.navigate(route.name);
          };

          const getNameIcon = () => {
            switch (index) {
              case 0:
                return isFocused ? 'homeActive' : 'home';
              case 1:
                return isFocused ? 'searchActive' : 'search';
              case 2:
                return isFocused ? 'healthActive' : 'health';
              case 3:
                return isFocused ? 'recordActive' : 'record';
              case 4:
                return isFocused ? 'accountActive' : 'account';
              default:
                return 'home';
            }
          };

          return (
            <TouchableOpacity
              accessibilityRole="button"
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              style={styles.btn}
              key={index}
              activeOpacity={1}
            >
              <View
                style={[styles.borderButton, isFocused && styles.borderActive]}
              >
                <Image source={ICON[getNameIcon()]} style={{ width: scale(24), height: scale(24) }} />
              </View>
            </TouchableOpacity>
          );
        })}
      </Layout>
    );
  }, [state, descriptors, navigation]);
};

const styles = StyleSheet.create({
  btn: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 52 + getBottomSpace(),
    paddingBottom: getBottomSpace(),
    backgroundColor: 'transparent',
  },
  container: {
    flexDirection: 'row',
  },
  borderButton: {
    width: 40,
    height: 40,
    borderRadius: 12,
    ...Theme.center,
  },
  borderActive: {
    backgroundColor: Colors.TealBlue20,
  },
});

export default MainTab;
