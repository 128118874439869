import appAccount from '@notfallkarte/app-account';
import appBlog from '@notfallkarte/app-blog';
import appData from '@notfallkarte/app-data';

const modules = [
  appAccount,
  appBlog,
  appData,
];

export default modules;