import axios from 'axios';
import { authService } from '@notfallkarte/app-account/services';

var apiBaseUrl = '/api';

if (process.env.NODE_ENV !== 'production') {
  apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
}

const instance = axios.create({
  baseURL: apiBaseUrl,
  timeout: 500000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(function (config) {
  var tokens;
  
  if (localStorage.getItem('auth.tokens')) {
    tokens = JSON.parse(localStorage.getItem('auth.tokens'));
  }

  return {
    ...config,
    headers: {
      authorization: tokens ? `Bearer ${tokens.access.token}` : null,
    },
  };
});

instance.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  const originalConfig = error.config;

  if (originalConfig.url !== '/auth/login' && error.response) {
    // Access Token was expired
    if (error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;

      try {
        var tokens;
  
        if (localStorage.getItem('auth.tokens')) {
          tokens = JSON.parse(localStorage.getItem('auth.tokens'));
        }
        
        const response = await authService.refreshTokens({ refreshToken: tokens.refresh.token });

        localStorage.setItem('auth.tokens', JSON.stringify(response))
  
        return instance(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  }

  return Promise.reject(error);
});

const responseBody = (response) => response.data;

const requests = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody),

  post: (url, body) => instance.post(url, body).then(responseBody),

  put: (url, body, headers) =>
    instance.put(url, body, headers).then(responseBody),

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url) => instance.delete(url).then(responseBody),
};

export default requests;