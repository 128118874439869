import Start from '../pages/Start';
import Home from '../pages/Home';

const linking = {
  Start: '',
  Home: 'home',
};

const stacks = [
  ['Start', Start, 'public', , { headerShown: false }],
  ['Home', Home, 'auth', , { headerShown: false }],
];

export default {
  linking,
  stacks,
};