import React, { memo, useCallback } from 'react';
import {
  View,
  StyleSheet,
  ImageSourcePropType,
  TouchableOpacity,
  ViewStyle,
  ImageBackground,
} from 'react-native';
import Text from '@notfallkarte/app-ui/src/elements/Text';
import Theme from '@notfallkarte/app-ui/src/style/Theme';
import { Colors } from '@notfallkarte/app-ui/src/configs';
import { useNavigation } from '@react-navigation/native';
import scale from '@notfallkarte/app-ui/src/utils/scale';
import { width } from '@notfallkarte/app-ui/src/configs/Const';
import { useTheme } from '@notfallkarte/app-ui/src/configs/ChangeTheme';

export default memo(({ route, routeOptions, img, title, numberNext, style, onPress }) => {
  const { navigate } = useNavigation();
  const handlePress = useCallback(() => {
    route && navigate(route, routeOptions);
    onPress?.();
  }, [route, navigate]);
  const { theme } = useTheme();

  return (
    <TouchableOpacity
      activeOpacity={0.54}
      style={[
        styles.container,
        style,
        { backgroundColor: theme.backgroundItem },
      ]}
      onPress={handlePress}
    >
      <View style={{ width: `${(88 / 156) * 100}%` }}></View>
      <ImageBackground
        style={{ width: scale(56), height: scale(56) }}
        source={img}
        resizeMode="cover"
      >
        <Text
          hilight
          marginTop={scale(8)}
          center
          size={scale(56)}
          color={Colors.TealBlue}
        >
          {numberNext}
        </Text>
      </ImageBackground>
      <Text marginTop={16} size={13} lineHeight={18} center>
        {title}
      </Text>
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    width: (width - 64) / 2,
    height: (width - 64) / 2,
    ...Theme.center,
    marginBottom: 16,
    borderRadius: 16,
    ...Theme.shadow,
  },
});
