import { useTheme } from '../../configs/ChangeTheme';
import React from 'react';
import {
  View as DefaultView,
  ViewProps,
  ViewStyle,
  PressableStateCallbackType,
} from 'react-native';

const Line = (props) => {
  const { theme } = useTheme();
  return (
    <DefaultView
      style={[
        {
          backgroundColor: theme.borderColor,
          height: 1,
        },
        props.style,
      ]}
    >
      {props.children}
    </DefaultView>
  );
};
export default Line;
