import React, { memo, useState } from 'react';
import { View, StyleSheet, Image, ScrollView } from 'react-native';
import Text from '@notfallkarte/app-ui/src/elements/Text';
import { IMAGE } from '@notfallkarte/app-ui/src/images/Image';
import { width } from '@notfallkarte/app-ui/src/configs/Const';
import ButtonIconHeader from '@notfallkarte/app-ui/src/elements/Buttons/ButtonIconHeader';
import Theme from '@notfallkarte/app-ui/src/style/Theme';
import {
  getBottomSpace,
  getStatusBarHeight,
} from 'react-native-iphone-x-helper';
import { Colors } from '@notfallkarte/app-ui/src/configs';
import ButtonLinear from '@notfallkarte/app-ui/src/elements/Buttons/ButtonLinear';
import { ICON } from '@notfallkarte/app-ui/src/images/Icon';
import Container from '@notfallkarte/app-ui/src/elements/Layout/Container';
import Layout from '@notfallkarte/app-ui/src/elements/Layout/Layout';
import {FOLLOWING_TOPIC, FOLLOWING_TOPIC_HEADER} from '@notfallkarte/app-ui/src/configs/Data';
import scale from '@notfallkarte/app-ui/src/utils/scale';
import {useTheme} from '@notfallkarte/app-ui/src/configs/ChangeTheme';

const HealthFeedTipsDetail = memo(({ route }) => {
  const { img, doctor, topicName, numberOfThanks, category, detail } =
  FOLLOWING_TOPIC[0];
  const { avatar, name, faculty } = doctor;
  const [itemThanked, setItemThanked] = useState(false);
  const onItemThank = () => {
    setItemThanked(!itemThanked);
  };
  const {theme} = useTheme();
  return (
    <Container style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false} scrollEventThrottle={16}>
        <Image source={img} style={styles.image} />
        <View style={styles.content}>
          <Text size={24} lineHeight={28} bold>
            {topicName}
          </Text>
          <Text
            marginTop={16}
            marginBottom={32}
            size={13}
            lineHeight={16}
            color={Colors.GrayBlue}
          >
            10.07.2023
          </Text>
          <View style={styles.doctor}>
            <Image source={avatar} style={{ width: scale(48), height: scale(48) }} />
            <View>
              <Text
                bold
                size={15}
                lineHeight={18}
                color={Colors.DodgerBlue}
                marginLeft={16}
                marginBottom={4}
              >
                Dr . {name}
              </Text>
              <Text size={13} lineHeight={16} marginLeft={16}>
                {faculty}
              </Text>
            </View>
          </View>
          <Text size={15} lineHeight={24} marginTop={27}>
            {detail}
          </Text>
        </View>
      </ScrollView>
      <View style={styles.headerIcon}>
        <ButtonIconHeader
          marginLeft={24}
          tintColor={theme.activeTincolor}
          backgroundColor={Colors.DarkJungleGreenOpacity}
        />
        <ButtonIconHeader
          marginRight={24}
          tintColor={theme.activeTincolor}
          backgroundColor={Colors.DarkJungleGreenOpacity}
          icon="share"
        />
      </View>
    </Container>
  );
});

export default HealthFeedTipsDetail;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerIcon: {
    width: width,
    top: getStatusBarHeight() + 24,
    position: 'absolute',
    ...Theme.flexRowSpace,
  },
  image: {
    width: width,
    height: 264,
  },
  content: {
    paddingHorizontal: 24,
    paddingVertical: 32,
  },
  doctor: {
    paddingVertical: 16,
    ...Theme.flexRow,
  },
  buttonBottom: {
    bottom: 0,
    paddingTop: 14,
    paddingHorizontal: 24,
    paddingBottom: getBottomSpace() + 24,
    position: 'absolute',
    width: width,
  },
});
