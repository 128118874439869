import React, { memo } from 'react';
import { View, StyleSheet } from 'react-native';
import Text from '../../../elements/Text';
import Theme from '../../../style/Theme';
import { Colors } from '../../../configs';
import ImageBackgroundCustom from '../../../components/TodayTasks/ImageBackgroundCustom';
import Container from '../../../elements/Layout/Container';

const TaskstForToday = memo((props) => {
  return (
    <Container style={styles.container}>
      <Container>
        <Text size={20} lineHeight={28} bold={true}>
          Angaben
          <Text size={20} lineHeight={28}>
            {' '}
            unvollständig
          </Text>
        </Text>
        <Text
          size={13}
          lineHeight={16}
          color={Colors.GrayBlue}
          marginTop={8}
          text_placeholder
        >
          {props.step || 5} von 9 ausgefüllt
        </Text>
      </Container>
      <ImageBackgroundCustom number={10 - props.step} onPress={props.onPress} />
    </Container>
  );
});

export default TaskstForToday;

const styles = StyleSheet.create({
  container: {
    ...Theme.flexRow,
    justifyContent: 'space-between',
  },
});
