import React from 'react';
import { Document } from './form';

function Documents() {
  return (
    <>
      {[1, 2, 3, 4].map(document => (
        <Document name={`document${document}`} label={`Dokument ${document}`} />
      ))}
    </>
  );
}

export default Documents;