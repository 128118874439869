import React, { memo, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import LinearColors from '@notfallkarte/app-ui/src/elements/LinearColors';
import { Colors, Constants, Routes } from '@notfallkarte/app-ui/src/configs';
import OnboardingPage from '@notfallkarte/app-ui/src/components/OnBoarding/OnBoardingPage';
import ButtonText from '@notfallkarte/app-ui/src/elements/Buttons/ButtonText';
import Text from '@notfallkarte/app-ui/src/elements/Text';
import {
  getBottomSpace,
  getStatusBarHeight,
} from 'react-native-iphone-x-helper';
import { useNavigation } from '@react-navigation/native';
import { ONBOARDING } from '@notfallkarte/app-ui/src/configs/Data';

const OnBoarding = memo(() => {
  const { navigate } = useNavigation();
  const onLogin = useCallback(() => {
    navigate('Login');
  }, [navigate]);
  const onSignUp = useCallback(() => {
    navigate('Register');
  }, [navigate]);

  return (
    <View style={styles.container}>
      <LinearColors
        style={StyleSheet.absoluteFillObject}
        colors={[Colors.TealBlue, Colors.TurquoiseBlue]}
      >
          {ONBOARDING.map((i, index) => (
            <OnboardingPage
              {...i}
              key={i.id.toString()}
              isFirstItem={index === 0}
              isLastItem={index === ONBOARDING.length - 1}
            />
          ))}
        <ButtonText
          white
          title={'Anmelden'}
          style={styles.loginButton}
          titleColor={Colors.White}
          textProps={{ bold: true }}
          onPress={onLogin}
          backgroundColor={Colors.TealBlue}
        />
        <ButtonText
          backgroundColor={Colors.White}
          hilight
          title={'Registrieren'}
          style={styles.signUpButton}
          titleColor={Colors.TealBlue}
          textProps={{ bold: true }}
          onPress={onSignUp}
        />
      </LinearColors>
    </View>
  );
});

export default OnBoarding;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    paddingBottom: getBottomSpace(),
    paddingTop: getStatusBarHeight(),
    backgroundColor: Colors.DodgerBlue,
  },
  loginButton: {
    width: (Constants.width - 88) / 2,
    height: 50,
    position: 'absolute',
    bottom: (Constants.height / 812) * 77 + getBottomSpace(),
    left: 32,
  },
  signUpButton: {
    width: (Constants.width - 88) / 2,
    height: 50,
    position: 'absolute',
    bottom: (Constants.height / 812) * 77 + getBottomSpace(),
    right: 32,
    borderColor: Colors.Platinum,
    borderWidth: 1,
  },
  changeApp: {
    position: 'absolute',
    bottom: 16 + getBottomSpace(),
    alignSelf: 'center',
  },
});