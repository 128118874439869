import React from 'react';
import { Button, StyleSheet, Text, View, Dimensions, TextInput } from 'react-native';

function Activate() {
  return (
    <View style={styles.container}>
      <View style={styles.text}>
        <Text>Aktivieren</Text>
      </View>
      <View style={styles.text}>
        <TextInput name="password" />
      </View>
      <View style={styles.text}>
        <TextInput name="password" />
      </View>
      <View style={styles.text}>
        <Button to={{ screen: 'Start' }} title="Aktivieren" />
      </View>
    </View>
  );
};

let ScreenHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: ScreenHeight,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5FCFF'
  },
  header: {
    padding: 20
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginVertical: '1em',
    textAlign: 'center'
  },
  text: {
    lineHeight: '1.5em',
    fontSize: '1.125rem',
    marginVertical: '1em',
    textAlign: 'center'
  },
  link: {
    color: '#1B95E0'
  },
  code: {
    fontFamily: 'monospace, monospace'
  }
});

export default Activate;