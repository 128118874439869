import { AppRegistry } from 'react-native';
import core from '@notfallkarte/app-core';
import App from './App';
import modules from './modules';

core.init(modules);

AppRegistry.registerComponent('App', () => App);

AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root')
});