import React from 'react';
import { FormControl, Button } from 'native-base';
import { useFormContext, Controller } from 'react-hook-form';

function Switch({ name, label }) {
  const { control, formState: { errors } } = useFormContext();

  return (
    <>
      <FormControl>
        <FormControl.Label>{label}</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
          <Button.Group isAttached colorScheme="blue" mx={{
            base: "auto",
            md: 0
          }} size="sm">
            <Button onPress={() => onChange(false)} variant={value ? 'outline' : 'solid'}>Nein</Button>
            <Button onPress={() => onChange(true)} variant={value ? 'solid' : 'outline'}>Ja</Button>
          </Button.Group>
          )}
          name={name}
          defaultValue=""
        />
      </FormControl>
    </>
  );
}

export default Switch;