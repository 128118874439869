import React from 'react';
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { StyleSheet, Dimensions } from 'react-native';
import { extendTheme, NativeBaseProvider, View } from 'native-base';
import theme from '@notfallkarte/app-core/theme';
import store from '@notfallkarte/app-core/redux/store';
import AppRoutes from './AppRoutes';
import { themes, ThemeContext } from '@notfallkarte/app-ui/src/configs/ChangeTheme';
import './App.css';

const queryConfig = {
  defaultOptions: {
    queries: {
      suspense: true,
      cacheTime: 0,
    },
  },
};

const queryClient = new QueryClient(queryConfig);

function App() {
  const newTheme = extendTheme(theme);
  const [mode, setMode] = React.useState('dark');

  const toggleTheme = React.useCallback(() => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  }, []);

  const themeX = React.useMemo(
    () => (mode === 'light' ? themes.dark : themes.light),
    [mode],
  );

  console.log(themeX);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <NativeBaseProvider theme={newTheme}>
          <ThemeContext.Provider value={{ theme: themeX, toggleTheme }}>
            <View style={styles.container}>
              <ErrorBoundary fallbackRender={() => (
                <div>
                  There was an error!
                </div>
              )}>
                <React.Suspense fallback={
                  <></>
                }>
                  <AppRoutes/>
                </React.Suspense>
              </ErrorBoundary>
              </View>
          </ThemeContext.Provider>
        </NativeBaseProvider>
      </Provider>
    </QueryClientProvider>
  );
}

let ScreenHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: '1',
  },
});

export default App;