import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import core from '@notfallkarte/app-core';
import Loading from '@notfallkarte/app-core/components/Loading';
import { navigationRef } from '@notfallkarte/app-core/utils/RootNavigation';
import routes from '@notfallkarte/app-core/routes';

const Stack = createStackNavigator();

function AppRoutes() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const moduleRoutes = core.getRoutes(core.modules);

  const linking = {
    config: {
      screens: {
        ...routes.linking,
        ...moduleRoutes.linking,
      },
    },
  };

  const stacks = [
    ...routes.stacks,
    ...moduleRoutes.stacks,
  ];

  React.useEffect(() => {
    if (state.state == 'not_initialized') {
      dispatch({ type: 'INITIALIZE', payload: { user: localStorage.getItem('auth.user') ? JSON.parse(localStorage.getItem('auth.user')) : null } });
    }
  }, []);

  return (state.state != 'not_initialized' &&
    <NavigationContainer ref={navigationRef} linking={linking} fallback={<Loading/>}>
      <Stack.Navigator initialRouteName={state.state == 'logged_in' ? 'Home' : 'Start'}
        screenOptions={{
          // headerShown: false,
          headerStyle: {
            minHeight: 0,
            maxHeight: 1000,
            opacity: 1, 
            transform: [],
            backgroundColor: '#ffffff',
          },
        }}
      >
      {
        stacks
          .filter((screen) => (screen[2] == 'all' || screen[2] == (state.state == 'logged_in' ? 'auth' : 'public')))
          .map((screen) =>
            <Stack.Screen key={screen[0]} name={screen[0]} component={screen[1]} getId={screen[3] ? screen[3] : undefined} options={screen[4]} />
          )
      }
    </Stack.Navigator>
  </NavigationContainer>
  );
}

export default AppRoutes;