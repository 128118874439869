import React from 'react';
import {
  View as DefaultView,
  ViewProps,
  ViewStyle,
  PressableStateCallbackType,
} from 'react-native';
import { useTheme } from '../../configs/ChangeTheme';

const Container = (props) => {
  const { theme } = useTheme();
  return (
    <DefaultView style={[{ backgroundColor: theme.background }, props.style]}>
      {props.children}
    </DefaultView>
  );
};
export default Container;
