import requests from '@notfallkarte/app-core/services/httpService';

const dataService = {
  getAllData() {
    return requests.get('/data');
  },
  getData(id) {
    return requests.get(`/data/${id}`);
  },
  setData(id, data) {
    return requests.put(`/data/${id}`, data);
  },
  getPublic() {
    return requests.get(`/data/public`);
  },
  setPublic(data) {
    return requests.put(`/data/public`, data);
  },
};

export default dataService;