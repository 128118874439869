import React from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { VStack, Text, View } from 'native-base';

function Page({ title, children }) {
  return (
    <View style={styles.container}>
      <VStack space={4} alignItems="center">
        <Text style={styles.title}>
          {title}
        </Text>
        {children}
      </VStack>
    </View>
  );
};

let ScreenHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: ScreenHeight,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5FCFF'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginVertical: '1em',
    textAlign: 'center'
  },
});

export default Page;