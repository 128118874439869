import React, { memo } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import Theme from '../../../style/Theme';
import Text from '../../../elements/Text';
import { AVATAR } from '../../../images/Avatar';
import { useSelector } from 'react-redux'
import scale from '../../../utils/scale';

const Greeting = memo((props) => {
  const state = useSelector((state) => state);

  return (
    <View style={styles.container}>
      <Image
        source={AVATAR.avatar2}
        style={{ width: 40, height: 40, marginRight: 16 }}
      />
      <View style={{ justifyContent: 'space-between', height: 40 }}>
        <Text size={17} lineHeight={37} bold>
          Hallo {state.user.name}
        </Text>
      </View>
    </View>
  );
});

export default Greeting;

const styles = StyleSheet.create({
  container: {
    ...Theme.flexRow,
    marginBottom: scale(30),
  },
});
