import requests from '@notfallkarte/app-core/services/httpService';

const authService = {
  authenticate(body) {
    return requests.post('/auth', body);
  },
  register(body) {
    return requests.post('/auth/register', body);
  },
  login(body) {
    return requests.post('/auth/login', body);
  },
  logout(body) {
    return requests.post('/auth/logout', body);
  },
  refreshTokens(body) {
    return requests.post('/auth/refresh-tokens', body);
  },
  forgotPassword(body) {
    return requests.post('/auth/forgot-password', body);
  },
  resetPassword(token, body) {
    return requests.post(`/auth/reset-password?token=${token}`, body);
  },
  verifyEmail(body) {
    return requests.post('/auth/verify-email', body);
  },
};

export default authService;