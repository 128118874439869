import React from 'react';
import { DateInput, TextInput } from './form';

function Personal() {
  return (
    <>
      <TextInput name="name" label="Name" />
      <DateInput name="dob" label="Geburtsdatum" />
    </>
  );
}

export default Personal;