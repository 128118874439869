export const ICON = {
  // example
  //nameIcon :require("./ic_name_icon.png"),
  home: require('./ic_home_normal.png'),
  homeActive: require('./ic_home_active.png'),
  notification: require('./ic_chat_active.png'),
  account: require('./ic_account_normal.png'),
  accountWhite: require('./ic_account_white.png'),
  accountActive: require('./ic_account_active.png'),
  back: require('./ic_back.png'),
  whiteArrow: require('./ic_white_arrow.png'),
  search: require('./ic_search_normal.png'),
  searchActive: require('./ic_search_active.png'),
  option: require('./ic_option.png'),
  greyOption: require('./ic_option_grey.png'),
  edit: require('./ic_edit.png'),
  share: require('./ic_share.png'),
  attack: require('./ic_attack.png'),
  send: require('./ic_send.png'),
  atoz: require('./ic_atoz.png'),
  medication: require('./ic_medication.png'),
  plus: require('./ic_plus.png'),
  minus: require('./ic_minus.png'),
  whitePlus: require('./ic_white_plus.png'),
  doctor: require('./ic_doctor.png'),
  hospital: require('./ic_hospital.png'),
  healthGuide: require('./ic_health_guide.png'),
  whiteMedication: require('./ic_white_medication.png'),
  rateFull: require('./ic_rate_full.png'),
  additional: require('./ic_addtional.png'),
  arrowRight: require('./ic_arrow_right.png'),
  videoCall: require('./ic_video.png'),
  voiceCall: require('./ic_voice_call.png'),
  liveChat: require('./ic_chat_live.png'),
  Message: require('./ic_message.png'),
  message: require('./ic_send_message.png'),
  additionalInformation: require('./ic_additional_information.png'),
  condition: require('./ic_condition.png'),
  procedure: require('./ic_procedure.png'),
  whiteCondition: require('./ic_white_condition.png'),
  whiteAdditional: require('./ic_white_additional.png'),
  close: require('./ic_close.png'),
  accountNormal: require('./ic_account_normal1.png'),
  follow: require('./ic_follow.png'),
  followed: require('./ic_followed.png'),
  recommended: require('./ic_recommended.png'),
  checkMark: require('./ic_checkmark.png'),
  backWhite: require('./ic_back_white.png'),
  help: require('./ic_help_white.png'),
  payment: require('./ic_payment.png'),
  term: require('./ic_term.png'),
  themeMode: require('./ic_theme_mode.png'),
  setting: require('./ic_setting.png'),
  eyeOn: require('./ic_eye_on.png'),
  starRate: require('./ic_star_rate.png'),
  addCareTeam: require('./ic_add_care_team.png'),
  addedCareTeam: require('./ic_added_care_team.png'),
  video: require('./ic_video_on.png'),
  appointmentActive: require('./ic_appointment_active.png'),
  photoLibrary: require('./ic_photo_library.png'),
  masterCard: require('./ic_master.png'),
  payPal: require('./ic_paypal.png'),
  amex: require('./ic_amex.png'),
  next: require('./ic_next.png'),
  calendar: require('./ic_calendar.png'),
  arrowDown: require('./down.png'),
  pinMap: require('./ic_pin_map.png'),
  healthMetric: require('./ic_health_metric.png'),
  allergies: require('./ic_allergies.png'),
  clinicVital: require('./ic_clinic_vital.png'),
  emergency: require('./ic_emergency.png'),
  guideName: require('./ic_guide_name.png'),
  labTest: require('./ic_lab_test.png'),
  vaccination: require('./ic_vaccination.png'),
  addPlan: require('./ic_add_plan.png'),
  filter: require('./ic_filter.png'),
  security: require('./ic_security.png'),
  nextGray: require('./ic_next_gray.png'),
  checkBoxActive: require('./ic_checkbox_active.png'),
  facebook: require('./ic_facebook.png'),
  twitter: require('./ic_twitter.png'),
  instagram: require('./ic_instagram.png'),
  addPatient: require('./ic_add_patient.png'),
  health: require('./ic_health_normal.png'),
  healthActive: require('./ic_health_active.png'),
  record: require('./ic_record_normal.png'),
  recordActive: require('./ic_record_active.png'),
  careTeam: require('./ic_care_team_16.png'),
  scan: require('./ic_scan.png'),
  tablet: require('./ic_tablet.png'),
  drink: require('./ic_drink.png'),
  radioActive: require('./ic_radio_active1.png'),
  more: require('./ic_more.png'),
  doctorAnswer: require('./ic_doctor_answer.png'),
  baseArrowRight: require('./ic_base_arrow_right.png'),
  verified: require('./ic_verified.png'),
  cardiology: require('./ic_cardiology.png'),
  deficiency: require('./ic_deficiency.png'),
  dermatology: require('./ic_dermatology.png'),
  disability: require('./ic_disability.png'),
  emergencyWhite: require('./ic_emergency_white.png'),
  recordWhite: require('./ic_record_white.png'),
  thanks: require('./ic_thanks.png'),
  topic: require('./ic_topic.png'),
  helpWhite: require('./ic_help_white.png'),
  arrDown: require('./ic_arr_down.png'),
  arrBlueUp: require('./ic_base_arrow_up.png'),
  attachBlue: require('./ic_attach_blue.png'),
  healthyBlue: require('./ic_health_guide_blue.png'),
  typeLiveChat: require('./ic_type_live_chat.png'),
  typeCall: require('./ic_type_call.png'),
  typeVideo: require('./ic_type_video.png'),
  arrUp: require('./ic_arr_up.png'),
  attach: require('./ic_attach.png'),
  securePay: require('./ic_secure_pay.png'),
  creditCard: require('./ic_credit_card.png'),
  applePay: require('./ic_apple_pay.png'),
  cancelSearch: require('./ic_reset_search.png'),
  loading: require('./loading.png'),
  history: require('./ic_history.png'),
  nearby: require('./ic_nearby.png'),
  currentLocation: require('./ic_current_location.png'),
  sideEffect: require('./ic_side_effect.png'),
  hospitalBed: require('./ic_hospital_bed.png'),
  call: require('./ic_call.png'),
  callOff: require('./ic_call_off.png'),
  sound: require('./ic_sound.png'),
  videoOff: require('./ic_video_off.png'),
  mute: require('./ic_mute.png'),
  quote: require('./ic_quote.png'),
  time: require('./ic_time.png'),
  arrowLeft: require('./ic_arrow_left.png'),
};
