import React from 'react';
import { useQuery } from 'react-query';
import { Button, useToast } from 'native-base';
import { useDropzone } from 'react-dropzone';
import { Link } from '@react-navigation/native';
import { Page } from '@notfallkarte/app-core/components';
import { fileService } from '../../services';

function Files({ navigation }) {
  const toast = useToast();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const fetchData = async () => {
    try {
      const response = await fileService.getFile('test');

      return response.data;
    } catch (error) {
      toast.show({
        title: (error.message),
        placement: 'top',
      });
    }
  };

  const query = useQuery('file', fetchData);

  console.log(query);

  const onSubmit = async () => {
    if (acceptedFiles.length == 0) {
      return;
    }

    try {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;

        const response = await fileService.setFile('test', { name: acceptedFiles[0].name, size: acceptedFiles[0].size, content: btoa(binaryStr) });

        toast.show({
          title: 'Daten gespeichert',
          placement: 'top',
        });

        navigation.navigate('Data');
      }

      console.log(acceptedFiles[0]);

      reader.readAsBinaryString(acceptedFiles[0])
    } catch (error) {
      toast.show({
        title: (error.message),
        placement: 'top',
      });
    }
  };

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <Page title="Dateien">
      <section className="container">
        <aside>
          <h4>Aktuell:</h4>
          <p><a href={`http://notfallkarte-appdev.h2labs.de:5000/api/files/test/content`} target="_blank">{query.isSuccess && query.data.name}</a></p>
        </aside>
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <aside>
          <h4>Ausgewählt:</h4>
          <p>{files}</p>
        </aside>
      </section>
      <Button onPress={onSubmit}>
        Speichern
      </Button>
      <Link to={{ screen: 'Data' }}>
        Abbrechen
      </Link>
    </Page>
  );
}

export default Files;