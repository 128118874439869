import React, { Dispatch, SetStateAction, memo } from 'react';
import { View, StyleSheet, ViewStyle, TouchableOpacity } from 'react-native';
import Text from '../../elements/Text';
import TextInput from '../../elements/TextInput';
import { Colors } from '../../configs';
import { themes, useTheme } from '../../configs/ChangeTheme';

const InputApp = memo(
  ({
    value,
    placeholder,
    onChangeText,
    isShowIcon,
    icon,
    secureTextEntry,
    style,
    styleView,
    title,
    colorTitle,
    borderColor,
    iconPress,
    autoFocus,
    isShowIconLeft,
    iconLeft,
    iconPressLeft,
    marginTop,
    multiline,
    editable = true,
    onPress,
    styleInput,
  }) => {
    const { theme } = useTheme();
    return (
      <TouchableOpacity
        style={[
          styleView,
          { marginTop: marginTop, borderColor: theme.borderColor },
        ]}
        onPress={onPress}
        disabled={!onPress}
        activeOpacity={0.7}
      >
        <Text type="H6" semiBold color={colorTitle}>
          {title}
        </Text>
        <TextInput
          {...{
            value,
            placeholder,
            onChangeText,
            isShowIcon,
            icon,
            secureTextEntry,
            borderColor,
            iconPress,
            autoFocus,
            isShowIconLeft,
            iconLeft,
            iconPressLeft,
            multiline,
            editable,
          }}
          style={{ marginTop: 4, ...style }}
        />
      </TouchableOpacity>
    );
  },
);

export default InputApp;

const styles = StyleSheet.create({
  container: {},
});
