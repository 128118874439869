export const IMAGE = {
  //example
  //abc:require('./img_abc.png');
  noPast: require('./img_no_past.png'),
  background: require('./img_background.png'),
  map: require('./img_map.png'),
  mapFull: require('./img_map_full.png'),
  bostonUniversity: require('./img_boston_university.png'),
  cigarette: require('./img_cigarette.png'),
  factory: require('./img_factory.png'),
  noEntry: require('./img-no-entry.png'),
  noUpcoming: require('./img_no_upcoming.png'),
  chickenpox: require('./img_topic_chickenpox.png'),
  cardiology: require('./img_topic_cardiology.png'),
  noCard: require('./img_no_card.png'),
  flag: require('./img_flag.png'),
  img1: require('./img-1.png'),
  img2: require('./img-2.png'),
  img3: require('./img-3.png'),
  img4: require('./img_4.png'),
  img5: require('./img-4.png'),
  img6: require('./img_6.png'),
  img7: require('./img_7.png'),
  img8: require('./img_8.png'),
  img9: require('./img_9.png'),
  askFreeQuestion: require('./img_ask_free_question.png'),
  bgShape: require('./bg_shape.png'),
  healthFeed: require('./img_health_feed.png'),
  consultMange: require('./img_consult_mange.png'),
  logo: require('./img_logo.png'),
  warning: require('./img_warning.png'),
  success: require('./img_success.png'),
  sentMessage: require('./img_sent_message.png'),
  inviteFriend: require('./img_invite_friend.png'),
  daughterTeeth: require('./img_daughter_teeth.png'),
  childDrink: require('./img_child_drink.png'),
  myQuestion: require('./img_my_question.png'),
  healthGuide: require('./img_health_guide.png'),
  careTeam: require('./img_care_team.png'),
  lovedOne: require('./img_loved_one.png'),
  medicineReminder: require('./img_medicine_reminder.png'),
  progress: require('./progress.png'),
  sentSuccessful: require('./img_sent_successful.png'),
  sentAppointment: require('./img_sent_appoinment.png'),
  fitbitHeart: require('./img_fitbit_health.png'),
  appleHealth: require('./img_apple_health.png'),
  rightLine: require('./img_right_line.png'),
  rocket: require('./img_rocket.png'),
  light: require('./img_light.png'),
  industry: require('./img_industry.png'),
  city: require('./img_city.png'),
  cigarret: require('./img_cigarret.png'),
  chimney: require('./img_chimney.png'),
  hospital: require('./img_hospital.png'),
  mountSinai: require('./img_mount_sinai.png'),
  nyp: require('./img_nyp.png'),
  westEnd: require('./img_west_end.png'),
  rmh: require('./img_rmh.png'),
  videoSuccessful: require('./img_video_successful.png'),
  doctor: require('./img_doctor.png'),
  patient: require('./img_patient.png'),
  chatSuccessful: require('./img_chat_successful.png'),
};
