import React, {memo} from 'react';
import {View, StyleSheet} from 'react-native';
import Theme from '../../../style/Theme';
import {Routes} from '../../../configs';
import FeatureItem from '../../../components/FeatureItem';
import {IMAGE} from '../../../images/Image';
import Container from '../../../elements/Layout/Container';
import { useDispatch, useSelector } from 'react-redux'
import { authService } from '@notfallkarte/app-account/services';
import { navigate } from '@notfallkarte/app-core/utils/RootNavigation';

const MainControl = memo(() => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const logout = async () => {
    if (localStorage.getItem('auth.tokens')) {
      const tokens = JSON.parse(localStorage.getItem('auth.tokens'));

      await authService.logout({ refreshToken: tokens.refresh.token });
    }

    localStorage.removeItem('auth.user');
    localStorage.removeItem('auth.tokens');

    dispatch({ type: 'LOGOUT' });

    navigate('Start');
  };

  const mainFeature = [
    {
      img: IMAGE.healthGuide,
      title: 'Notfallseite',
      route: 'Data',
    },
    {
      img: IMAGE.askFreeQuestion,
      title: 'Magazin',
      route: 'BlogList',
    },
    {
      img: IMAGE.healthFeed,
      title: 'Benutzerkonto',
      route: 'Account',
    },
    {
      img: IMAGE.consultMange,
      title: 'Abmelden',
      onPress: logout,
    },
  ];

  return (
    <Container style={styles.container}>
      {mainFeature.map(item => (
        <FeatureItem {...item} key={item.title} />
      ))}
    </Container>
  );
});

export default MainControl;

const styles = StyleSheet.create({
  container: {
    ...Theme.flexDirection,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: 45,
  },
});
